/** @format */

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                            Basic Style
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

* {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease !important;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif !important;
}

body {
  -webkit-user-drag: none !important;
  -moz-drag-over: none !important;
  /* Firefox */
}

a {
  text-decoration: none !important;
}

body::-webkit-scrollbar-track {
  background: #f1f5f8;
}

::selection {
  background: #b02a30;
  color: #d2ab67;
}

body::-webkit-scrollbar-thumb {
  background-color: #b02a30;
  border: 2px solid #b02a30;
  border-radius: 6px;
}

body::-webkit-scrollbar {
  width: 4px;
}

body {
  width: 100% !important;
  scroll-behavior: smooth;
  background: #fff !important;
}

.collapse {
  visibility: visible !important;
}

.navbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px;
}

.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
}

.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #b02a30;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #b02a30;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar-toggler {
  border: none !important;
}

.navbar {
  .active {
    font-weight: bold;
    color: #d2ab67 !important;
    opacity: 1 !important;
  }
}

/*
headeer top
*/
.topbar {
  background-color: #b02a30;
  padding: 0;
}

.topbar .container .row {
  margin: -7px;
  padding: 0;
}

.topbar .container .row .col-md-12 {
  padding: 0;
}

.topbar p {
  margin: 0;
  display: inline-block;
  font-size: 13px;
  color: #f1f6ff;
}

.topbar p > i {
  margin-right: 5px;
}

.topbar p:last-child {
  text-align: right;
}

header .navbar {
  margin-bottom: 0;
}

.dropdown-menu {
  border: 0 !important;
  break-before: 4px !important;
}

.dropdown-menu .active {
  background: #b02a30 !important;
}

.dropdown-item:hover {
  color: #b02a30 !important;
}

.dropdown-item:focus {
  color: #d2ab67 !important;
  background: #b02a30 !important;
}

.topbar li.topbar {
  display: inline;
  padding-right: 18px;
  line-height: 52px;
  transition: all 0.3s linear;
}

.topbar li.topbar:hover {
  color: #1bbde8;
}

.topbar ul.info i {
  color: #ffff;
  font-style: normal;
  margin-right: 8px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.topbar ul.info li {
  float: right;
  padding-left: 30px;
  color: #ffffff;
  font-size: 13px;
  line-height: 44px;
}

.topbar ul.info i span {
  color: #aaa;
  font-size: 13px;
  font-weight: 400;
  line-height: 50px;
  padding-left: 18px;
}

ul.social-network {
  border: none;
  margin: 0;
  padding: 0;
}

ul.social-network li {
  border: none;
  margin: 0;
}

ul.social-network li i {
  margin: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
  border: 0px solid #2d2d2d;
  padding: 5px 0 0;
  width: 32px;
  display: inline-block;
  text-align: center;
  height: 32px;
  vertical-align: baseline;
  color: #fff;
}

ul.social-network {
  list-style: none;
  margin: 5px 0 10px -25px;
  float: right;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: 0.3s ease-out;
  color: #fff;
}

li {
  list-style-type: none;
}

.bg-image-full {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.mx-background-top-linear {
  background: -webkit-linear-gradient(45deg, #d2ab67 38%, #b02a30 38%);
  background: -webkit-linear-gradient(left, #d2ab67 38%, #b02a30 38%);
  background: linear-gradient(45deg, #d2ab67 38%, #b02a30 38%);
}

.emoji-404 {
  position: relative;
  animation: mymove 2.5s infinite;
}

@keyframes mymove {
  33% {
    top: 0px;
  }

  66% {
    top: 20px;
  }

  100% {
    top: 0px;
  }
}

.btns {
  padding: 0;
  border: none;
  transform: rotate(5deg) !important;
  transform-origin: center;
  text-decoration: none;
  display: block;
  font-size: 15px;
  cursor: pointer;
  padding-bottom: 3px;
  border-radius: 5px;
  box-shadow: 0 2px 0 #494a4b;
  color: #000;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important;
  background-color: #d2ab67;

  span {
    background: #f1f5f8;
    display: block;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    border: 2px solid #494a4b;

    &:active {
      transform: translateY(5px) !important;
      padding-bottom: 0px;
      outline: 0;
    }
  }
}

.footer-10 {
  background: whitesmoke;
}

.footer-10 .border-top,
.footer-10 .border-bottom {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.footer-10 .footer-heading {
  font-size: 18px;
  color: #000;
  margin-bottom: 30px;
}

.footer-10 a {
  color: #1089ff;
}

.footer-10 .con.con-1 {
  background: #fff;
}

.footer-10 .con.con-2 {
  background: #fff;
}

.footer-10 .con.con-3 {
  background: #fff;
}

.footer-10 .list-unstyled li a {
  color: rgb(255, 255, 255);
}

.footer-10 .list-unstyled li a:hover {
  color: #fff;
}

.footer-10 .con-info .icon {
  width: 50px;
  height: 50px;
  background: #d2ab67;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.footer-10 .con-info .icon span {
  color: #fff5f5;
  font-size: 24px;
}

.footer-10 .con-info span {
  color: rgb(3, 2, 2);
}

.footer-10 .ftco-footer-social li a {
  background: #d2ab67;
  color: #fff;
}

.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block;
}

.ftco-footer-social li a {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}

.ftco-footer-social li a span {
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ftco-footer-social li a:hover {
  background: #b02a30;
}

ul.Medical {
  margin: 3px 0 8px -15px;

  li {
    list-style: disc;
    text-transform: capitalize !important;
    margin-top: 10px;

    &::marker {
      color: #b02a30 !important;
    }
  }
}
@media only screen and (max-width: 600px) {
  .mx-background-top-linear {
    background: -webkit-linear-gradient(65deg, #d2ab67 55%, #d2ab67 55%);
    background: -webkit-linear-gradient(left, #d2ab67 55%, #d2ab67 55%);
    background: linear-gradient(45deg, #d2ab67 65%, #d2ab67 35%);
color: #000;
  }
  ul.social-network {
    list-style: none;
    margin: 5px 0 10px -25px;
    float: none;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
}
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}
td:first-child {
  font-weight: bold;
}
td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
